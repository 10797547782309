import {
    Button,
    Card, Col,
    Form, Image,
    Input,
    Modal, Row, Select, Switch, Tag, Tooltip,
    Typography,
} from "antd";
import {
    CloseOutlined, DisconnectOutlined, DownloadOutlined,
    LinkOutlined, LoadingOutlined,
    SettingOutlined,
} from "@ant-design/icons";

import React, {useCallback, useEffect, useState} from "react";
import {
    createZAPIInstance,
    deleteZAPIInstance,
    getZAPIInstance, getZAPIInstanceById,
    updateZAPIInstance
} from "../../services/zAPIInstances";

export function ZAPIIntegration({icon, label, description, assistantID, messageApi}) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [form] = Form.useForm();
    const onlyWithoutTag = Form.useWatch('onlyWithoutTag', form);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [configOpen, setConfigOpen] = useState(false);

    useEffect(() => {
        setLoading(true)
        getZAPIInstance({assistantID}).then(({data}) => {
            if (data.length === 0)
                return
            setData(data[0])
        }).catch((error) => {
            console.log(error)
            messageApi.error("Erro ao buscar integração com z-api.");
        }).finally(() => {
            setLoading(false)
        });
    }, [assistantID, messageApi]);

    const install = useCallback((values) => {
        setButtonLoading(true)
        createZAPIInstance({
            assistantID: parseInt(assistantID),
            active: true,
            ...values
        }).then(({data}) => {
            setData(data);
            setConfigOpen(false);
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao instalar integração com z-api.");
        }).finally(() => {
            setButtonLoading(false);
        });
    }, [assistantID, messageApi]);

    const uninstall = useCallback(() => {
        setButtonLoading(true);
        deleteZAPIInstance(data.id).then(() => {
            setData(undefined);
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao remover integração com z-api.");
        }).finally(() => {
            setButtonLoading(false)
        });
    }, [data, messageApi]);

    const update = useCallback((newData) => {
        setButtonLoading(true);
        updateZAPIInstance(data.id, {...data, ...newData}).then(({data: updatedData}) => {
            setData({...updatedData});
            setConfigOpen(false);
            messageApi.success("Atualizado com sucesso!");
        }).catch((error) => {
            console.log(error);
            messageApi.error("Erro ao atualizar.");
        }).finally(() => {
            setButtonLoading(false);
        });
    }, [data, messageApi]);

    const finishForm = useCallback((values) => {
        if (!data)
            return install(values);

        update(values)
    }, [data])

    useEffect(() => {
        form.setFieldsValue(data);
    }, [data, form]);


    return (
        <>
            <Modal title="Configurar Z-API"
                   open={configOpen}
                   cancelText={'Cancelar'}
                   confirmLoading={buttonLoading}
                   okText={'Salvar'}
                   onOk={form.submit}
                   onCancel={() => setConfigOpen(false)}>
                <br/>

                <Form layout={'vertical'}
                      form={form}
                      onFinish={finishForm}>
                    <Form.Item label={'Instancia'} name={'instance'}>
                        <Input placeholder={'ID da instancia do z-api'} autocomplete="off"/>
                    </Form.Item>

                    <Form.Item label={'Token'} name={'token'}>
                        <Input placeholder={'Token da instancia z-api'}/>
                    </Form.Item>
                    <Form.Item label={'Responder apenas sem etiqueta'}
                               name={'onlyWithoutTag'}
                               valuePropName={"checked"}
                               help={'Responder apenas a contatos que não tiverem nenhum etiqueta.'}>
                        <Switch/>
                    </Form.Item>

                    {!onlyWithoutTag && <Form.Item label={'Tags'}
                                name={'tags'}
                                help={'Preencha caso deseje que que o bot só responda clientes com determinadas etiquetas.'}>
                        <Select
                            mode="tags"
                            style={{width: '100%'}}
                            placeholder="Digite tags"
                            tokenSeparators={[',']}
                            open={false}
                        />
                    </Form.Item>}
                    <Form.Item label={'Definir tag quando enviado pelo app'}
                               name={'setTagFromApp'}
                               help={'Preencha caso deseje que o bot defina uma etiqueta quando alguem mandar uma mensagem atraves do app do whatsapp.'}>
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
            <Card
                loading={loading}
                title={<Typography.Title level={5} ellipsis={{tooltip: label}}>{icon} {label}</Typography.Title>}
                extra={
                    <Button type={data ? 'default' : 'primary'}
                            loading={loading || buttonLoading}
                            danger={!!data}
                            onClick={() => data ? uninstall() : setConfigOpen(true)}
                            icon={data ? <CloseOutlined/> : <DownloadOutlined/>}
                    >{loading ? undefined : data ? 'Remover' : 'Instalar'}</Button>
                }
                actions={data ? [
                    <Tooltip title={'Em breve será possível desconectar'}>
                        <DisconnectOutlined/>
                    </Tooltip>,
                    <SettingOutlined onClick={() => setConfigOpen(true)}/>,
                    <a href={"https://wa.me/" + data?.phoneNumber?.replaceAll(" ", "").replace("-", "")}
                       target={'_blank'}>
                        <LinkOutlined/>
                    </a>
                ] : undefined}>
                <Configuration setData={setData} messageApi={messageApi} data={data}/>
            </Card>
        </>
    )
}

function Configuration({data, messageApi, setData}) {
    const [checking, setChecking] = useState(false);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        if (!data?.status?.smartphoneConnected) {
            setIsConnected(false);
            return;
        }
        setIsConnected(true);
    }, [data?.status?.smartphoneConnected]);

    useEffect(() => {
        if (!data?.id || data?.status?.connected)
            return;

        let time = 3000;
        let limit = 40;
        let i = 0;
        const interval = setInterval(() => {
            setChecking(true)
            getZAPIInstanceById(data.id).then(({data}) => {
                setData(data);
                if (data.status?.smartphoneConnected) {
                    clearInterval(interval);
                }
            }).catch(e => {
                console.log(e);
                messageApi.error("Falha ao checar status");
            }).finally(() => {
                setChecking(false);
            })

            i++;
            if (i > limit) clearInterval(interval);
        }, time)

        return () => clearInterval(interval);
    }, [data?.id, data?.status?.connected]);

    const Disconnected = () => 
        <Row justify={'center'} wrap>
            <Tag color={'error'} icon={<DisconnectOutlined/>} style={{
                justifySelf: 'center',
                margin: 0,
                marginBottom: 12
            }}>{checking ? "Conectando..." : "Desconectado"} {checking && <LoadingOutlined/>}</Tag>
            <Col span={24}/>
            <Image src={data.qrCodeImage} height={150}/>
        </Row>

    const Connected = () =>  
    <Typography.Text>
        Z-API configurada e operando normalmente
    </Typography.Text>

    if (!data) return <Typography>
        Configure o whatsapp com sua instancia do <a target={'_blank'} href={'https://z-api.io'}>z-api</a>.
    </Typography>
    
    return (
        <>{isConnected ? <Connected /> : <Disconnected />}</>
    )
}
